// Glyphicons font path
$icon-font-path:        "../fonts/";

// Fonts
$roboto:                'Roboto', sans-serif;
$robotoCondensed:       'Roboto Condensed', sans-serif;

// Grid settings
$main-sm-columns:           12;
$sidebar-sm-columns:        4;
$grid-float-breakpoint:     992px;

// Colors
$white:                     #fff;
$black:                     #000;
$dark-gray:                 #231f20;
$green:                     #2ac143;
$red:                       #cc3d3f;
$brand-primary:             #27ae60;
$gray:                      #e3e3e3;

// Header Colors:
$menu-color:                $white;
$ticket-info-color:         $white;
$ticket-info-bg:            $green;
$hamburger-bg:              $white;
$header-intro-color:        $white;
$header-date-color:         $black;
$header-lead-color:         $white;
$header-lead-border:        $white;
$header-buy-ticket-bg:      $black;
$header-buy-ticket-color:   $white;

// Footer Colors:
$footer-countdown-color:    $black;
$footer-contact-bg-color:   $black;
$footer-contact-color:      $white;
$footer-social-color:       $black;

// Front Page Colors:
$home-content-title-color:  $black;
$home-content-color:        $black;
$home-read-more-color:      $black;
$home-quote-color:          $black;
$home-quota-bg:             $gray;
$home-team-name-color:      $red;
$home-team-name-border:     $red;
$home-team-name-bg:         $black;
$home-team-post-color:      $white;
$home-team-post-bg:         $black;
$home-sponsor-bg:           $dark-gray;

// Sub Page Colors:
$subpage-header-color:      $black;
$subpage-content-color:    $black;
