header {
  &.banner {
    /*background: url("../images/header-mobil-bg.png") top center no-repeat;
    @media (min-width: $screen-sm-min) {
      background: url("../images/header-tablet-bg.png") top center no-repeat;
    }
    @media (min-width: $screen-md-min) {
      background: url("../images/header-desktop-bg.png") top left no-repeat;
    }
    @media (min-width: $screen-lg-min) {
      background: url("../images/header-large-bg.png") top left no-repeat;
      background-size: cover;
    }*/
    /*background: url("../images/header-bg.png") top center no-repeat;*/
    .header-menu {
      list-style: none;
      padding-left: 0;
      text-align: center;
      margin-bottom: 0;
      @media (min-width: $screen-sm-min) {
        position: absolute;
        right: 275px;
      }
      li {
        @media (min-width: $screen-sm-min) {
          float: left;
        }
        a {
          @include robotoFonts($robotoCondensed, 700, 20px, red);
          padding-top: 3px;
          padding-bottom: 3px;
          width: 100%;
          display: block;
          text-transform: uppercase;
          @media (min-width: $screen-sm-min) {
            padding: 15px;
            color: white;
          }
        }
      }
      .active {
        a {
          text-decoration: underline;
        }
      }
    }
    .navbar-header {
      margin-left: 50px;
      @media (min-width: $screen-sm-min) {
        position: absolute;
        top: 0;
        right: 0;
      }
      @media (min-width: $screen-md-min) {
        float: right;
        right: 15px;
      }
      .navbar-brand {
        float: right;
        @include robotoFonts($roboto, 700, 18px, $ticket-info-color);
        background: $ticket-info-bg;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        min-width: 160px;
        @media (min-width: $screen-sm-min) {
          position: fixed;
          right: 0;
          width: 160px;
          z-index: 1000;
        }
        @media (min-width: $screen-md-min) {
          margin-right: -15px;
          width: 175px;

        }
      }
      .navbar-toggle {
        float: left;
        .menu-icon {
          float: left;
          .icon-bar {
            background: $hamburger-bg;
            margin-right: 10px;
            width: 30px;
            height: 4px;
            border-radius: 0;
          }
        }
        .menu-icon-label {
          float: right;
          @include robotoFonts($roboto, 700, 15px, $menu-color);
          text-transform: uppercase;
        }
      }
    }
    .social-icons {
      float: left;
      width: 280px;
      @media (min-width: $screen-sm-min) {
        position: absolute;
        right: 0px;
      }
      .fb, .yt {
        border-top: 0;
        width: 50px;
        height: 50px;
        display: block;
        float: left;
        @media (min-width: $screen-sm-min) {
          border: 2px solid white;
        }
      }
      .fb {
        background: #3B5998 url("../images/facebook.png") no-repeat center center;
        background-size: 30%;
        @media (min-width: $screen-sm-min) {
          background-color: transparent;
        }
      }
      .yt {
        background: url("../images/youtube.png") no-repeat center center;
        background-size: 60%;
        border-left: 1px;
      }
    }
    .navbar-collapse {
      @media (min-width: $screen-md-min) {
        float: right;
      }
      .navbar-nav {
        &>li {
          &>a {
            @include robotoFonts($robotoCondensed, 700, 20px, $menu-color);
            text-transform: uppercase;
            &:hover, &:focus {
            background: transparent;
          }
          }
        }
      }
    }

    .intro {
      @include robotoFonts($robotoCondensed, 400, 15px, $header-intro-color);
      text-align: center;
      p {
        max-width: 286px;
        margin: 0 auto;
        text-align: left;
        @media (min-width: $screen-sm-min) {
          max-width: 694px;
        }
        @media (min-width: $screen-md-min) {
          max-width: 970px;
          margin: 0;
          padding-left: 110px;
        }
        @media (min-width: $screen-lg-min) {
          padding-left: 210px;
          max-width: 1718px;
          margin: 0 auto;
        }
      }
      img {
        margin: 0 auto;
        @media (min-width: $screen-md-min) {
          margin: 0;
          padding-left: 110px;
        }
        @media (min-width: $screen-lg-min) {
          margin: 0 auto;
          padding-left: 210px;
        }
      }
    }
    .date {
      max-width: 286px;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      padding-top: 10px;
      padding-bottom: 10px;
      @include robotoFonts($robotoCondensed, 700, 21px, $header-date-color);
      @media (min-width: $screen-sm-min) {
        max-width: 694px;
      }
      @media (min-width: $screen-md-min) {
        max-width: 970px;
        text-align: left;
        font-size: 41px;
        margin: 0;
        padding-left: 110px;
      }
      @media (min-width: $screen-lg-min) {
        max-width:1200px;
        padding-left: 210px;
      }
      p {
        margin-bottom: 0;
        span {
          color: white;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
    .lead {
      max-width: 286px;
      margin: 0 auto;
      text-align: center;
      img {
        display: inline-block;
      }
      @media (min-width: $screen-sm-min) {
        max-width: 694px;
      }
      @media (min-width: $screen-md-min) {
        max-width: 963px;
        padding-left: 110px;
        margin: 0;
        text-align: right;
        img {
          margin-top: 45px;
          margin-bottom: 20px;
          display: inline-block;
        }
      }
      @media (min-width: $screen-lg-min) {
        max-width: 1718px;
        margin: 0 auto;
        img {
          margin-top: 60px;
          margin-bottom: 40px;
        }
      }
      .top {
        text-align: center;
        @include robotoFonts($robotoCondensed, 700, 20px, $header-lead-color);
        text-transform: uppercase;
        border-bottom: 4px solid $header-lead-border;
        p {
          margin-bottom: 0;
        }
      }
      .bottom {
        text-align: center;
        @include robotoFonts($robotoCondensed, 700, 13px, $header-lead-color);
        @media (min-width: $screen-sm-min) {
          font-size: 17px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
.buy-ticket {
  background: $header-buy-ticket-bg;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  p {
    padding-top: 7px;
    padding-bottom: 7px;
    text-align: center;
    margin-bottom: 0;
    @media (min-width: $screen-sm-min) {
      padding-top: 9px;
      padding-bottom: 9px;
    }
    @media (min-width: $screen-md-min) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @media (min-width: $screen-lg-min) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    a {
      @include robotoFonts($robotoCondensed, 700, 12px, $header-buy-ticket-color);
      text-transform: uppercase;
      @media (min-width: $screen-lg-min) {
        font-size: 15px;
      }
    }
  }
}

.buy {
  background: #f84509 url("../images/ticket_icon.svg") no-repeat 5%;
  background-size: contain;
  border: 0;
  width: 180px;
  height: 50px;
  display: inline-block;
  text-align: right;
  float: none;
  line-height: 50px;
  box-sizing: border-box;
  padding-right:10px;
  color: white;
  text-decoration: none;
  font-family:Roboto Condensed, sans-serif;
  transition: all 0.3s;
  &:hover{
    text-decoration: none;
    color: white;
    background-color: darken(#f84509, 10%);
  }
}

.esemeny_tile{
  z-index: 0;
  height: 200px;
  border: 2px solid white;
  background: no-repeat;
  background-position: center;
  background-size: 110%;
  transition: all 0.3s;
  transform: scale(1);
  &:hover{
    border:none;
    transform: scale(1.2);
    z-index: 2;
  }
}

