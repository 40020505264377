footer {
  &.content-info {
    margin-bottom: 34px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 38px;
    }
    @media (min-width: $screen-md-min) {
      margin-bottom: 40px;
    }
    @media (min-width: $screen-lg-min) {
      margin-bottom: 45px;
    }
    #clockdiv {
      text-align: center;
      padding-top: 100px;
      padding-bottom: 100px;
      h3 {
        @include robotoFonts($robotoCondensed, 700, 25px, $footer-countdown-color);
        text-transform: uppercase;
      }
      .days, .hours, .minutes, .seconds {
        @include robotoFonts($robotoCondensed, 700, 84px, $footer-countdown-color);
      }
      .smalltext {
        @include robotoFonts($robotoCondensed, 400, 40px, $footer-countdown-color);
        margin-top: -30px;
      }
    }
    .contact {
      background: $footer-contact-bg-color;
      padding-top: 0;
      padding-bottom: 25px;
      text-align: center;
      @media (min-width: $screen-sm-min) {

      }
      p {
        @include robotoFonts($roboto, 400, 15px, $footer-contact-color);
        text-align: left;
        max-width: 270px;
        margin: 0 auto 3px;
        &.title {
          @include robotoFonts($robotoCondensed, 700, 15px, $footer-contact-color);
          padding-top: 45px;
          margin: 0 auto 15px;
        }
      }
      .social {
        text-align: center;
        margin-top: 20px;
        @media (min-width: $screen-sm-min) {
          margin-top: 45px;
          text-align: left;
          margin-left: 20px;
        }
        span {
          margin: 0 10px 20px;
          .fa-inverse {
            color: $footer-social-color;
          }
        }
      }
      .ticket-buy {
        @media (max-width: $screen-xs-max) {
          text-align: center;
        }
        a {
          color: black;
          background: white;
          padding: 12px;
          margin-top: 20px;
          display: inline-block;
          border-radius: 20px;
          text-transform: uppercase;
          font-size: 16px;
          margin-left: -10px;
          @media (max-width: $screen-xs-max) {
            margin-left: auto;
          }
        }

      }
    }
  }
}