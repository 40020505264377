@mixin robotoFonts($font, $weight, $size, $color) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

@mixin blackAnchor() {
  font-family: $roboto;
  font-weight: 700;
  font-size: 25px;
  color: $home-read-more-color;
}

