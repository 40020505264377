.home {
  .section {
    &.content {
      max-width: 1620px;
      margin: 0 auto;
      .front-title {
        h1 {
          @include robotoFonts($robotoCondensed, 700, 50px, $home-content-title-color);
          text-transform: uppercase;
          text-align: center;
          margin-top: 45px;
          margin-bottom: 35px;
          @media (min-width: $screen-sm-min) {
            margin-top: 55px;
          }
          @media (min-width: $screen-md-min) {
            margin-bottom: 45px;
          }
          @media (min-width: $screen-lg-min) {
            margin-top: 80px;
            margin-bottom: 50px;
          }
        }
        h3 {
          @include robotoFonts($robotoCondensed, 700, 25px, $home-content-title-color);
        }
      }
      .the_content {
        margin-bottom: 20px;
        p {
          @include robotoFonts($roboto, 400, 12px, $home-content-color);
          max-width: 1100px;
          margin: 0 auto;
          @media (min-width: $screen-sm-min) {
            font-size: 15px;
          }
          @media (min-width: $screen-lg-min) {
            font-size: 20px;
          }
        }
      }
    }
    &.team {
      max-width: 1620px;
      margin: 0 auto;
      background: url("../images/vorosmarty_idezet.png") no-repeat;
      padding-top: 30px;
      padding-bottom: 60px;
      .producer, .main-characters {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 40px;
        .team-more, .first-line  {
          @include robotoFonts($robotoCondensed, 700, 24px, $home-team-name-color);
          text-transform: uppercase;
          width: 100%;
          max-width: 665px;
          background: $home-team-name-bg;
          padding-top: 45px;
          padding-bottom: 45px;
          border-radius: 0;
          span {
            @include robotoFonts($roboto, 400, 24px, $home-team-post-color);
          }
        }
        .thumbnail {
          .caption {
            max-width: 259px;
            margin: 0 auto;
            padding: 0 0 15px;
            background: $home-team-post-bg;
            h3 {
              @include robotoFonts($robotoCondensed, 700, 24px, $home-team-name-color);
              text-transform: uppercase;
              max-width: 200px;
              margin: 0 auto 5px auto;
              text-align: center;
              border-bottom: 2px solid $home-team-name-border;
              padding-top: 20px;
              padding-bottom: 10px;
            }
            p {
              @include robotoFonts($roboto, 400, 18px, $home-team-post-color);
              text-transform: uppercase;
              text-align: center;
            }
          }
        }
      }
      .producer {
        padding-right: 0px;
        text-align: center;
        .members {
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 15px;
          text-align: center;
          img {
            margin: 0 auto;
          }
          .first-line {
            padding-top: 22px;
            padding-bottom: 22px;
            max-width: 327px;
            margin: 0 auto;
            &.left {
              @media (min-width: $screen-lg-min) {
                padding-top: 23px !important;
              }
            }
            span {
              border-top: 2px solid $home-team-name-border;
              display: block;
              padding-top: 5px;
              max-width: 200px;
              margin: 5px auto 0;
            }
          }
        }
      }
      .main-characters {
        padding-left: 0;
        .members {
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 15px;
          text-align: center;
          img {
            margin: 0 auto;
          }
          .team-more {
            padding-top: 22px;
            padding-bottom: 22px;
            max-width: 306px;
            margin: 0 auto;
            span {
              border-top: 2px solid $home-team-name-border;
              display: block;
              padding-top: 5px;
              max-width: 200px;
              margin: 5px auto 0;
            }
          }
        }
      }
      .black {
        @include blackAnchor();
        &.read-more {
          display: block;
          margin-top: 100px;
          margin-bottom: 10px;
          &:hover, &:focus {
            text-decoration: none;
          }
        }
      }
      h3 {
        @include robotoFonts($robotoCondensed, 700, 25px, $home-content-title-color);
      }
      p {
        @include robotoFonts($roboto, 400, 12px, $home-content-color);
        max-width: 1100px;
        margin: 0 auto;
        @media (min-width: $screen-sm-min) {
          font-size: 15px;
        }
        @media (min-width: $screen-lg-min) {
          font-size: 20px;
        }
      }
    }
    &.video {
      max-width: 1100px;
      margin: 0 auto;
      padding-bottom: 50px;
      h3 {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 25px;
      }
    }
    &.hosok-tere {
      background: url("../images/hosok-tere-mobil.png") no-repeat center top;
      height: 105px;
      width: 100%;
      margin-left: -7px;
      margin-right: -15px;
      @media (min-width: $screen-sm-min) {
        background: url("../images/hosok-tere-tablet.png") no-repeat center top;
      }
      @media (min-width: $screen-md-min) {
        background: url("../images/hosok-tere-desktop.png") no-repeat center top;
        margin-left: -4px;
      }
    }
    &.quota {
      background: url("../images/quota-bg-mobil.png") no-repeat center top;
      margin-left: -15px;
      margin-right: -15px;
      padding-bottom: 100px;
      @media (min-width: $screen-sm-min) {
        background: url("../images/quota-bg-tablet.png") no-repeat center top;
      }
      @media (min-width: $screen-md-min) {
        background: $home-quota-bg url("../images/quota-bg-desktop.png") no-repeat center top;
      }
      h3 {
        @include robotoFonts($roboto, 700, 25px, $home-quote-color);
        text-align: center;
        text-transform: uppercase;
        padding-top: 75px;
        padding-bottom: 30px;
        margin-top: 0;
      }
      p {
        @include robotoFonts($roboto, 400, 15px, $home-quote-color);
        font-style: italic;
        text-align: center;
        max-width: 1100px;
        margin: 0 auto;
      }
    }
    &.sponsor {
      background: $home-sponsor-bg;
      margin-right: -15px;
      margin-left: -15px;
      .media {
        max-width: 530px;
        margin: 0 auto;
        .media-left {
          img {
            max-width: 100px;
            @media (min-width: $screen-sm-min) {
              max-width: 200px;
            }
          }
        }
        .media-body {
          vertical-align: middle;
          color: white;
        }
      }
    }
  }
}