.sajtomegjelenesek, .jelmezek {
  .wrap {
    .content {
      p, div, h1, h2 {
        max-width: 1100px;
        margin: 0 auto;
      }
      p {
        @include robotoFonts($roboto, 400, 20px, $subpage-content-color);
      }
      h1, h2 {
        @include robotoFonts($robotoCondensed, 700, 35px, $subpage-header-color);
        margin-top: 20px;
        margin-bottom: 10px;
      }
      a {
        word-wrap: break-word;
      }
    }
  }
}
figure {
  .gallery-item {
    float: left;
    padding: 20px;
  }
}


.gallery {
  margin-top: 30px !important;
}
.thumbnail {
  border: 0;
}